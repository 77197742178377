import { useEffect, useState } from "react";

export const useCurrentTimezone = () => {
  const [timezone, setTimezone] = useState<string>("");

  const formatTimezone = (timezoneId: string): string => {
    try {
      const now = new Date();
      const offsetInMinutes = now.getTimezoneOffset();

      return `${timezoneId} (GMT${offsetInMinutes >= 0 ? "+" : ""}${
        offsetInMinutes / -60
      })`;
    } catch (error) {
      console.error(error);
      return `${timezoneId} (Unable to retrieve offset)`;
    }
  };

  useEffect(() => {
    const getCurrentTimezone = () => {
      const options = Intl.DateTimeFormat().resolvedOptions();
      const humanReadableTimezone = formatTimezone(
        options.timeZone || "Unknown",
      );
      setTimezone(humanReadableTimezone);
    };

    getCurrentTimezone();
  }, []);

  return timezone;
};
